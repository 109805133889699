import { useEffect } from "react";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { useNavigate } from "react-router-dom";
import {
  RESPONSE_STATUS,
  timeSnackbar,
  guestUserLoginActions,
} from "@src/utils/constants";
import GoogleLogo from "@src/resources/images/google-logo.svg";
import { useGoogleLogin } from "@react-oauth/google";
import { Button, Icon } from "@mui/material";
import { connect } from "react-redux";
import {
  getExternalUserCodeFlow,
  signUpExternalUserCodeFlow,
  clearMessages,
} from "@src/actions/userActions";
import { withSnackbar } from "@src/components/SnackBarComponent";

const GoogleLoginRegisterButton = ({
  authReducers,
  removeMessages,
  className,
  // eslint-disable-next-line no-shadow
  getExternalUserCodeFlow,
  // eslint-disable-next-line no-shadow
  signUpExternalUserCodeFlow,
  isSignUpForm = false,
  fullWidth = true,
  onLoginCallback,
  buttonText,
  onValidate,
  isOnboarding = false,
  ...props
}) => {
  const { t, pathT } = useTranslatedNavigate();
  const navigate = useNavigate();

  const signIn = async (res) => {
    try {
      const response = await getExternalUserCodeFlow(res.code, "Google");

      props.snackbarShowMessage(response.message, "success");
      if (response?.twoFactorVerification === "pending") {
        // do nothing
        return;
      }
      setTimeout(() => {
        if (response.isRegistered) {
          navigate(pathT("route.account"), { replace: true });
        } else if (onLoginCallback) {
          onLoginCallback();
        }
      }, timeSnackbar);
    } catch (error) {
      if (error?.response?.status === RESPONSE_STATUS.NOT_FOUND) {
        props.snackbarShowMessage(t("LoginView.AccountNotFound"));
        setTimeout(() => {
          navigate(pathT("route.register"), { replace: true });
        }, timeSnackbar);
      } else {
        props.snackbarShowMessage("");
      }
    }
  };

  const signUp = async (res) => {
    try {
      const response = await signUpExternalUserCodeFlow(res.code, "Google");

      props.snackbarShowMessage(response.message, "success");

      if (response?.twoFactorVerification === "pending") {
        // do nothing, there's a useEffect that will redirect
        return;
      }
      setTimeout(() => {
        if (isOnboarding) {
          onLoginCallback();
          return;
        }

        if (!response.isRegistered) {
          onLoginCallback();
        } else {
          navigate(pathT("route.account"), { replace: true });
        }
      }, timeSnackbar);
    } catch (error) {
      props.snackbarShowMessage("");
    }
  };

  const onSuccess = async (res) => {
    if (isSignUpForm) {
      await signUp(res);
    } else {
      await signIn(res);
    }
  };

  const onFailure = (res) => {
    if (res?.error !== "popup_closed_by_user") {
      props.snackbarShowMessage(res.details);
    }
  };

  const login = useGoogleLogin({
    onSuccess: (response) => onSuccess(response),
    flow: "auth-code",
    onError: (error) => onFailure(error),
  });

  const getSignUpFormButtonText = () =>
    isSignUpForm
      ? t("RegisterView.GoogleRegister")
      : t("LoginView.GoogleLogin");

  useEffect(() => {
    if (!authReducers.twoFactorPending || authReducers.isLoggedIn) {
      return;
    }

    if (isOnboarding) {
      navigate(
        `${pathT("route.twoFactorCodeRequest")}?action=${
          guestUserLoginActions.explicitConsent
        }`,
        {
          replace: true,
        },
      );
    } else {
      navigate(pathT("route.twoFactorCodeRequest"), {
        replace: true,
      });
    }
    removeMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps -- TODO: fix this during refactoring
  }, [authReducers.twoFactorPending]);

  const validateLogin = () => {
    if (onValidate) {
      const response = onValidate();
      if (!response) {
        return;
      }
    }
    login();
  };

  return (
    <Button
      variant="outlined"
      fullWidth={fullWidth}
      size="large"
      startIcon={<GoogleIcon />}
      className={className}
      onClick={validateLogin}
    >
      <p className="font-semibold text-sm tracking-wide leading-6">
        {buttonText || getSignUpFormButtonText()}
      </p>
    </Button>
  );
};

const GoogleIcon = () => {
  return (
    <Icon>
      <img alt="" src={GoogleLogo} height="22px" width="22px" />
    </Icon>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getExternalUserCodeFlow: (idToken, provider) =>
    dispatch(getExternalUserCodeFlow(idToken, provider)),
  signUpExternalUserCodeFlow: (idToken, provider) =>
    dispatch(signUpExternalUserCodeFlow(idToken, provider)),
  removeMessages: () => {
    dispatch(clearMessages());
  },
});

const mapStateToProps = (state) => state;
export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(GoogleLoginRegisterButton),
);
