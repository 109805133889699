import { forwardRef } from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    minHeight: "100%",
  },
}));

interface PageProps {
  children: React.ReactNode;
  backgroundColor?: string;
  title?: string;
}

const Page = forwardRef<HTMLDivElement, PageProps>(
  ({ children, title = " ", backgroundColor, ...rest }: PageProps, ref) => {
    const classes = useStyles();

    return (
      <div
        ref={ref}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...rest}
        className={classes.root}
        style={{ backgroundColor: backgroundColor ?? "" }}
      >
        <Helmet title={title} />
        {children}
      </div>
    );
  },
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Page;
