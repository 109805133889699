import { Navigate } from "react-router-dom";
import NotLoggedInLayout from "@src/layouts/NotLoggedInLayout";
import DashboardLayout from "@src/layouts/DashboardLayout";
import LibraryLayout from "@src/layouts/LibraryLayout";
import ThemeLayout from "@src/layouts/ThemeLayout";
import VideoCallLayout from "@src/layouts/VideoCallLayout";
import HomeView from "@src/views/home/HomeView";
import LibraryView from "@src/views/account/LibraryView";
import LoginView from "@src/views/auth/LoginView";
import NotFoundView from "@src/views/errors/NotFoundView";
import RegisterView from "@src/views/auth/RegisterView/RegisterView";
import TwoFactorCodeRequestView from "@src/views/auth/TwoFactorCodeRequestView";
import VerifyEmailPage from "@src/views/auth/VerifyEmailPage";
import LinkExpiredView from "@src/views/auth/LinkExpiredView";
import EmailActivateView from "@src/views/auth/EmailActivateView";
import DashboardView from "@src/views/account/DashboardView";
import InvalidActionView from "@src/views/auth/InvalidActionView";
import ForgotPasswordView from "@src/views/auth/ForgotPasswordView";
import ForgotPasswordResetView from "@src/views/auth/ForgotPasswordResetView";
import SentPasswordResetEmail from "@src/views/auth/SentPasswordResetEmail";
import CheckInsView from "@src/views/account/CheckInsView";
import MySessionsView from "@src/views/account/mySessions/MySessionsView";
import SessionReviewView from "@src/views/account/mySessions/SessionReviewView";
import SessionReviewThanksView from "@src/views/account/mySessions/SessionReviewThanksView";

import MedicalTripettoSurvey from "@src/components/TripettoSurvey/MedicalTripettoSurvey";
import GlobalTripettoSurvey from "@src/components/TripettoSurvey/GlobalTripettoSurvey";
import ThemeTripettoSurvey from "@src/components/TripettoSurvey/ThemeTripettoSurvey";
import FocusThemesTripettoSurvey from "@src/components/TripettoSurvey/FocusThemesTripettoSurvey";

import AuthorizedExternalView from "@src/views/auth/AuthorizedExternalView";
import AuthorizedGuestView from "@src/views/auth/AuthorizedGuestView";
import ConfirmConsultView from "@src/views/auth/ConfirmConsultView";

import ThemeView from "@src/views/account/ThemeView";

import BookingView from "@src/views/booking/BookingView";
import FollowUpFlow from "@src/views/booking/followUp/FollowUpFlow";
import RescheduleFlow from "@src/views/booking/reschedule/RescheduleFlow";
import IntroductoryFlow from "@src/views/booking/introductory/IntroductoryFlow";
import ConfirmSessionFlow from "@src/views/booking/introductory/ConfirmSessionFlow";
import ConfirmSpaceFlow from "@src/views/booking/spaces/ConfirmSpaceFlow";
import RebookFlow from "@src/views/booking/rebook/RebookFlow";
import PersonalLinkView from "@src/views/booking/personalLink/PersonalLinkView";
import OnboardingLayout from "@src/layouts/OnboardingLayout";
import FlowLayout from "@src/layouts/FlowLayout";
import BookingCancellationFlow from "@src/views/booking/cancel/BookingCancellationFlow";
import PersonalFollowUpFlow from "@src/views/booking/followUp/personalLink/PersonalFollowUpFlow";
import CheckUpResultsView from "@src/views/account/MedicalCheckInResultsView";
import PostMedicalCheckInFlow from "@src/views/medical/PostMedicalCheckInFlow";
import MedicalCheckInNotFound from "@src/views/account/MedicalCheckInNotFound";
import GoToMobileAppStore from "@src/views/chat/GoToMobileAppStore";
import SessionNotesView from "@src/views/account/mySessions/SessionNotesView";
import ResilienceCheckinLayout from "@src/layouts/ResilienceCheckinLayout";
import ResilienceCheckinEntryView from "@src/views/resilience-checkin/ResilienceCheckEntryView";
import ExplicitConsentWrapper from "@src/components/ExplicitConsentWrapper";
import SessionContainer from "@src/views/account/mySessions/components/SessionContainer";
import CancelSpaceFlow from "@src/views/booking/spaces/CancelSpaceFlow";
import SingleSignOnEmailView from "@src/views/auth/SingleSignOnView/SingleSignOnEmailView";
import AuthRedirectUri from "@src/views/auth/SingleSignOnView/AuthRedirectUri";
import ThemePageMenuLayout from "./layouts/ThemePageMenuLayout";
import OnboardingView from "./views/onboarding/OnboardingView";
import WrappedLocalizationRedirectView from "./views/auth/LocalizationRedirectView";
import ProfileView from "./views/account/ProfileView";
import CourseView from "./views/courses/CourseView";
import CourseLessonView from "./views/courses/CourseLessonView";
import CreateAccountView from "./views/auth/CreateAccount/CreateAccountView";
import MakeCall from "./views/account/MakeCall/MakeCall";

const routes = (isLoggedIn, t) => {
  const pathT = (key) => `/${t(key)}`;

  /**
   * Routes that are only available when you are logged in
   */
  const protectedRoute = (routeConfig) => {
    if (isLoggedIn) {
      return routeConfig;
    }

    const {
      location: { pathname },
    } = window;

    const redirectURI = `${pathT("route.login")}${
      pathname !== pathT("route.login")
        ? `?redirect=${encodeURIComponent(pathname)}`
        : ""
    }`;

    return {
      path: routeConfig.path,
      element: <Navigate to={redirectURI} />,
      children: routeConfig.children?.map((child) => ({
        path: child.path,
        element: <Navigate to={redirectURI} />,
      })),
    };
  };

  /**
   * Routes that are only available when you are not logged in
   */
  const publicOnlyRoute = (routeConfig) =>
    !isLoggedIn
      ? routeConfig
      : {
          path: routeConfig.path,
          element: <Navigate to={pathT("route.account")} />,
          children: routeConfig.children?.map((child) => ({
            path: child.path,
            element: <Navigate to={pathT("route.account")} />,
          })),
        };

  return [
    {
      path: pathT("route.welcome-onboarding"),
      element: <OnboardingLayout />,
      children: [
        {
          path: "",
          element: <OnboardingView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.welcome-onboarding")} />,
        },
      ],
    },
    {
      path: "auth/pwc",
      element: <AuthRedirectUri />,
    },
    {
      path: pathT("route.spaces"),
      element: <FlowLayout />,
      children: [
        {
          path: `${t("route.confirmSpace")}`,
          element: <ConfirmSpaceFlow />,
        },
        {
          path: `${t("route.spaceCancellation")}/:spaceId`,
          element: <CancelSpaceFlow />,
        },
      ],
    },
    {
      path: pathT("route.booking"),
      element: <FlowLayout />,
      children: [
        {
          path: "",
          element: <BookingView />,
        },
        {
          path: `${t("route.reschedule")}/:consultId/:step`,
          element: <RescheduleFlow />,
        },
        {
          path: `${t("route.reschedule")}/:consultId`,
          element: <RescheduleFlow />,
        },
        {
          path: `${t("route.cancellation")}/:consultId/:step`,
          element: <BookingCancellationFlow />,
        },
        {
          path: `${t("route.cancellation")}/:consultId`,
          element: <BookingCancellationFlow />,
        },
        {
          path: `${t("route.follow_up")}/:step`,
          element: <FollowUpFlow />,
        },
        {
          path: `${t("route.follow_up")}`,
          element: <FollowUpFlow />,
        },
        {
          path: `${t("route.rebook")}/:rebookConsultId/:step`,
          element: <RebookFlow />,
        },
        {
          path: `${t("route.rebook")}/:rebookConsultId`,
          element: <RebookFlow />,
        },
        {
          path: `${t("route.introductory")}/:step`,
          element: <IntroductoryFlow />,
        },
        {
          path: `${t("route.introductory")}`,
          element: <IntroductoryFlow />,
        },
        {
          path: `${t("route.confirmConsult")}/:token/:email/:step`,
          element: <ConfirmSessionFlow />,
        },
        {
          path: `${t("route.confirmConsult")}/:token/:email`,
          element: <ConfirmSessionFlow />,
        },
        {
          path: ":urlHandle/:step",
          element: <PersonalLinkView />,
        },
        {
          path: ":urlHandle",
          element: <PersonalLinkView />,
        },
        {
          path: `:urlHandle/${t("route.follow_up")}/:step`,
          element: <PersonalFollowUpFlow />,
        },
        {
          path: `:urlHandle/${t("route.follow_up")}`,
          element: <PersonalFollowUpFlow />,
        },
      ],
    },
    {
      path: pathT("route.emailActivate"),
      element: <EmailActivateView />,
    },
    {
      path: pathT("route.authorized"),
      element: <AuthorizedExternalView />,
    },
    {
      path: pathT("route.authorizeGuest"),
      element: <AuthorizedGuestView />,
    },
    {
      path: pathT("route.confirmConsult"),
      element: <ConfirmConsultView />,
    },
    {
      path: "",
      element: <HomeView />,
    },
    {
      path: t("route.changeLanguage"),
      element: <WrappedLocalizationRedirectView />,
    },
    // Login is a special case of publicOnlyRoute, because redirecting is handled manually inside the component
    {
      path: "",
      element: <NotLoggedInLayout />,
      children: [
        { path: t("route.login"), element: <LoginView /> },
        { path: t("route.welcome"), element: <VerifyEmailPage /> },
      ],
    },
    {
      path: pathT("route.createAccount"),
      element: <FlowLayout />,
      children: [
        {
          path: "",
          element: <CreateAccountView />,
        },
        {
          path: ":step",
          element: <CreateAccountView />,
        },
      ],
    },
    {
      path: pathT("route.singleSignOn"),
      element: <OnboardingLayout />,
      children: [
        {
          path: "",
          element: <SingleSignOnEmailView />,
        },
      ],
    },
    {
      path: pathT("route.register"),
      element: <OnboardingLayout isRegister />,
      children: [
        {
          path: "",
          element: <RegisterView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.register")} />,
        },
      ],
    },
    protectedRoute({
      path: t("route.ResilienceCheckin.Entry"),
      element: <ResilienceCheckinLayout />,
      children: [
        {
          path: "",
          element: <ResilienceCheckinEntryView />,
        },
        {
          path: ":step",
          element: <Navigate to={pathT("route.ResilienceCheckin.Entry")} />,
        },
      ],
    }),
    {
      path: t("route.register.explicit-consent"),
      element: <OnboardingLayout isRegister />,
      children: [
        {
          path: "",
          element: <ExplicitConsentWrapper />,
        },
      ],
    },
    // Add all routes that reuse main layout here, as the '' exact path is already matched with home above
    publicOnlyRoute({
      path: "",
      element: <NotLoggedInLayout />,
      children: [
        // TODO: This should be done as query parameter, not a parameterized route
        { path: `${t("route.login")}/:isFromCheckIn?`, element: <LoginView /> },
        { path: t("route.register"), element: <RegisterView /> },
        {
          path: t("route.twoFactorCodeRequest"),
          element: <TwoFactorCodeRequestView />,
        },
        { path: t("route.forgotPassword"), element: <ForgotPasswordView /> },
        { path: t("route.emailLinkExpired"), element: <LinkExpiredView /> },
        { path: t("route.invalidAction"), element: <InvalidActionView /> },
        {
          path: t("route.emailForgotPassword"),
          element: <ForgotPasswordResetView />,
        },
        {
          path: t("route.sentPasswordReset"),
          element: <SentPasswordResetEmail />,
        },
      ],
    }),
    // Protected MainLayout routes
    protectedRoute({
      path: pathT("route.account"),
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <DashboardView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.library"),
      element: <LibraryLayout />,
      children: [
        {
          path: "",
          element: <LibraryView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.profile"),
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <ProfileView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.course"),
      element: <DashboardLayout />,
      children: [
        {
          path: ":courseId/:lessonId",
          element: <CourseLessonView />,
        },
        {
          path: ":courseId",
          element: <CourseView />,
        },
        {
          path: "",
          element: <CourseView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.call"),
      element: <VideoCallLayout />,
      children: [
        {
          path: ":consultId",
          element: <MakeCall />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions"),
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <MySessionsView />,
          children: [
            {
              path: "",
              element: <SessionContainer />,
            },
            {
              path: `${t("route.mySessions.consult")}/:consultId`,
              element: <SessionNotesView />,
            },
          ],
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions.review"),
      element: <VideoCallLayout />,
      children: [
        {
          path: "",
          element: <SessionReviewView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.mySessions.review.thanks"),
      element: <VideoCallLayout />,
      children: [
        {
          path: "",
          element: <SessionReviewThanksView />,
        },
      ],
    }),
    protectedRoute({
      path: pathT("route.checkins"),
      element: <DashboardLayout />,
      children: [
        {
          path: "",
          element: <CheckInsView />,
        },
      ],
    }),
    {
      path: pathT("route.themes"),
      element: <ThemeLayout />,
      children: [
        {
          path: "",
          element: <Navigate to={t("route.account")} />,
        },
        protectedRoute({
          path: ":themeName",
          element: <ThemePageMenuLayout />,
          children: [
            {
              path: "",
              element: <ThemeView />,
            },
            {
              path: ":slug",
              element: <ThemeView />,
            },
          ],
        }),
      ],
    },
    {
      path: pathT("route.themes"),
      element: <ThemeLayout showFooter={false} />,
      children: [
        protectedRoute({
          path: `:themeName/${t("route.checkin")}`,
          element: <ThemeTripettoSurvey />,
        }),
        protectedRoute({
          path: t("route.focusCheckin"),
          element: (
            <FocusThemesTripettoSurvey redirectUri={pathT("route.checkins")} />
          ),
        }),
        protectedRoute({
          path: t("route.checkin"),
          element: (
            <GlobalTripettoSurvey redirectUri={pathT("route.checkins")} />
          ),
        }),
      ],
    },
    {
      path: t("route.guest"),
      element: <DashboardLayout showFooter={false} />,
      children: [
        {
          path: `${t("route.checkin")}/:themeName`,
          element: (
            <ThemeTripettoSurvey
              redirectUriOnNotLoggedIn={pathT("route.createAccount")}
            />
          ),
        },
        {
          path: t("route.checkin"),
          element: (
            <GlobalTripettoSurvey
              redirectUriOnNotLoggedIn={pathT("route.createAccount")}
            />
          ),
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <DashboardLayout isMedical showFooter={false} />,
      children: [
        {
          path: t("route.medical-checkin"),
          element: (
            <MedicalTripettoSurvey
              redirectUriOnNotLoggedIn={`${pathT("route.medical")}/${t(
                "route.medical.checkIn.post",
              )}`}
            />
          ),
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <DashboardLayout isMedical />,
      children: [
        {
          path: t("route.medical-checkin-results"),
          element: <CheckUpResultsView />,
        },
      ],
    },
    {
      path: t("route.medical"),
      element: <FlowLayout isMedical />,
      children: [
        {
          path: `${t("route.medical.checkIn.post")}/:step`,
          element: <PostMedicalCheckInFlow />,
        },
        {
          path: `${t("route.medical.checkIn.post")}`,
          element: <PostMedicalCheckInFlow />,
        },
        {
          path: `${t("route.medical.checkIn.notFound")}/:step`,
          element: <MedicalCheckInNotFound />,
        },
        {
          path: `${t("route.medical.checkIn.notFound")}`,
          element: <MedicalCheckInNotFound />,
        },
      ],
    },
    { path: t("route.chat.invite"), element: <GoToMobileAppStore /> },
    {
      path: "*",
      element: <NotFoundView showNavBar />,
    },
  ];
};

export default routes;
