import { connect } from "react-redux";
import { makeStyles } from "@mui/styles";
import Page from "@src/components/Page";
import MenuList from "@src/components/OpenUpMenuList";
import { withSuspense } from "@src/components/wrappers/Suspendable";
import CourseInfo from "@openup/shared/components/CourseInfo/CourseInfo";
import LessonCard, {
  LessonCardProps,
} from "@openup/shared/components/Cards/LessonCard/LessonCard";
import BackgroundImage from "@src/resources/backgrounds/ellipse-bg.svg";
import Button from "@openup/shared/components/Button/Button";
import { Skeleton, Hidden, Grid, Container } from "@mui/material";
import { useQueryCourse } from "@src/queries/courses";
import { useNavigate, useParams } from "react-router-dom";
import { MaterialIconProps } from "@openup/shared/components/MaterialIcon/MaterialIcon";
import { upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { type Lesson, LessonType, EngagementStatus } from "@src/models/Course";
import { useTranslation } from "react-i18next";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import NotFoundCard from "@openup/shared/components/Cards/NotFoundCard/NotFoundCard";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    background: `url(${BackgroundImage}) no-repeat`,
  },
  header: {
    marginBottom: theme.spacing(3),
    color: theme.colors.primaryDarkBlue,
    [theme.breakpoints.down("md")]: {
      lineHeight: "48px",
      fontSize: 35,
    },
    [theme.breakpoints.up("sm")]: {
      lineHeight: "64px",
    },
  },
  menuGrid: {
    backgroundColor: theme.colors.trueWhite,
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-3.75),
    marginRight: theme.spacing(6),
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(3.75),
    marginLeft: theme.spacing(-2),
    paddingLeft: theme.spacing(2),
  },
  welcomeText: {
    fontWeight: "400",
    lineHeight: "32px",
    letterSpacing: "0.5px",
    opacity: 0.7,
    color: theme.colors.primaryDarkBlue,
    marginBottom: theme.spacing(6),
  },
  boxWelcomeText: {
    margin: `${theme.spacing(0)} auto`,
  },
}));

export const getLessonTypeKey = (type: Lesson["type"]) => {
  switch (type) {
    case LessonType.Article:
      return "LessonArticle";
    case LessonType.Video:
      return "LessonVideo";
    case LessonType.Audio:
      return "LessonAudio";
    case LessonType.Quiz:
      return "LessonQuiz";
    default:
      return "";
  }
};

export const getLessonIcon = (
  type: Lesson["type"],
): MaterialIconProps["icon"] => {
  switch (type) {
    case LessonType.Article:
      return "ArticleOutlined";
    case LessonType.Video:
      return "Slideshow";
    case LessonType.Audio:
      return "GraphicEq";
    case LessonType.Quiz:
      return "QuizOutlined";
    default:
      return undefined;
  }
};

const getCourseInfoStatus = (status: EngagementStatus | undefined) => {
  switch (status) {
    case null:
      return "new";
    case "started":
      return "unfinished";
    case "completed":
    default:
      return "completed";
  }
};

const CourseViewContent = ({ courseId }) => {
  const classes = useStyles();
  const { data: course, isLoading } = useQueryCourse(
    courseId ? parseInt(courseId, 10) : 0,
  );
  const [activeLessonIndex, setActiveLessonIndex] = useState(0);
  const { t } = useTranslation();
  const { pathT } = useTranslatedNavigate();
  const navigate = useNavigate();
  const [courseStatus, setCourseStatus] = useState<
    EngagementStatus | undefined
  >();

  const getLessonStatus = (
    status: Lesson["engagementStatus"] | null,
    index: number,
    length: number,
  ): LessonCardProps["status"] => {
    if (status === "started") {
      if (activeLessonIndex !== index) {
        setActiveLessonIndex(index);
      }
      return undefined;
    }

    if (status === null && activeLessonIndex === index) {
      return undefined;
    }

    if (status === "completed") {
      if (activeLessonIndex === index && activeLessonIndex !== length - 1) {
        setActiveLessonIndex(index + 1);
      }

      return "completed";
    }

    if (status === null && activeLessonIndex < index) {
      return "locked";
    }

    return undefined;
  };

  const onStartClick = () => {
    if (!course?.lessons || course.lessons.length === 0) {
      return;
    }

    const nextLesson = course.lessons.find(
      ({ engagementStatus }) =>
        engagementStatus === "started" || engagementStatus === null,
    );
    if (!nextLesson) {
      return;
    }
    navigate(`${pathT("route.course")}/${courseId}/${nextLesson.id}`);
  };

  useEffect(() => {
    if (!course?.lessons) {
      setCourseStatus(undefined);
      return;
    }

    if (
      course.lessons.some(
        ({ engagementStatus }) => engagementStatus === "started",
      )
    ) {
      setCourseStatus("started");
      return;
    }

    if (
      course.lessons.every(
        ({ engagementStatus }) => engagementStatus === "completed",
      )
    ) {
      setCourseStatus("completed");
      return;
    }

    if (
      course.lessons.some(({ engagementStatus }) => engagementStatus === null)
    ) {
      if (
        course.lessons.some(
          ({ engagementStatus }) => engagementStatus === "completed",
        )
      ) {
        setCourseStatus("started");
        return;
      }
      setCourseStatus(null);
    }
  }, [course]);

  return (
    <Page title={course?.title}>
      <Container maxWidth="lg" className={classes.container}>
        <Grid container>
          <Hidden only={["xs", "sm"]}>
            <Grid item xs className={classes.menuGrid}>
              <MenuList menuItemStyle={{ color: "#ffffff" }} isOpen />
            </Grid>
          </Hidden>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
            <CourseInfo
              title={course?.title || ""}
              topic={
                course?.themes?.[0]
                  ? t(`SharedStrings.${upperFirst(course.themes[0])}`)
                  : ""
              }
              estimatedTime={
                course?.duration
                  ? t("SharedStrings.EstimatedMinutes", {
                      time: Math.round(course.duration / 60),
                    })
                  : ""
              }
              imageSrc={
                course?.coverImage.large || course?.coverImage.source || ""
              }
              bulletPoints={course?.learningGoals || []}
              onStartClick={onStartClick}
              isLoading={isLoading}
              status={getCourseInfoStatus(courseStatus)}
              className="sm:pb-12"
            />
            <div className="-ml-12 -mr-4 bg-white pl-12 pr-4 sm:pr-8 py-8 sm:py-[110px] -mb-7">
              <div className="flex items-center gap-4 flex-col sm:flex-row">
                <div className="grow w-full sm:w-auto">
                  {isLoading ? (
                    <>
                      <Skeleton className="w-full sm:w-[240px] h-[64px]" />
                      <Skeleton className="w-full h-[24px]" />
                      <Skeleton className="w-3/4 h-[24px]" />
                    </>
                  ) : (
                    <>
                      <p className={`h2 !mb-0 ${classes.header}`}>
                        {t("CourseMenu.CourseOverview")}
                      </p>
                      <p className="body1 mt-4">{course?.description}</p>
                    </>
                  )}
                </div>
                {courseStatus !== undefined && courseStatus !== "completed" && (
                  <Button
                    variant="tertiary"
                    className="shrink-0 w-full sm:w-auto"
                    size="large"
                    disabled={isLoading}
                    onClick={onStartClick}
                  >
                    {courseStatus === null && t("CourseProgress.StartCourse")}
                    {courseStatus === "started" &&
                      t("CourseProgress.ContinueCourse")}
                  </Button>
                )}
              </div>
              <ul className="list-none mt-8 overflow-auto rounded-2xl p-6 shadow-xl">
                {isLoading
                  ? Array.from({ length: 5 }, (_, index) => (
                      <li
                        // eslint-disable-next-line react/no-array-index-key
                        key={index}
                        className="py-1 my-[1px] border-0 border-solid border-b border-slate-200"
                      >
                        <LessonCard
                          title={undefined}
                          imageSrc={undefined}
                          isLoading
                        />
                      </li>
                    ))
                  : course?.lessons?.map(
                      (
                        {
                          title,
                          coverImage,
                          engagementStatus,
                          type,
                          duration,
                          id,
                        },
                        index,
                      ) => (
                        <li
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          className="py-1 border-0 border-solid border-b border-slate-200"
                        >
                          <LessonCard
                            className="w-full"
                            title={title}
                            imageSrc={coverImage.thumbnail}
                            status={getLessonStatus(
                              engagementStatus,
                              index,
                              course.lessons.length,
                            )}
                            contentType={t(
                              `SharedStrings.${getLessonTypeKey(type)}`,
                            )}
                            href={`${pathT("route.course")}/${courseId}/${id}`}
                            contentTypeIconName={getLessonIcon(type)}
                            estimatedTime={
                              course?.duration
                                ? t("SharedStrings.EstimatedMinutes", {
                                    time: duration,
                                  })
                                : ""
                            }
                          />
                        </li>
                      ),
                    )}
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

const CourseView = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { pathT } = useTranslatedNavigate();

  const { courseId } = params;

  if (!courseId) {
    return (
      <div className="flex justify-center items-center h-screen p-4">
        <NotFoundCard
          goBackAction={() => {
            navigate(pathT("route.account"));
          }}
        />
      </div>
    );
  }

  return <CourseViewContent courseId={courseId} />;
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(withSuspense(CourseView));
export { CourseView as CourseViewForTesting };
