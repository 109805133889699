import { Paper } from "@mui/material";
import MarkdownPreview from "@uiw/react-markdown-preview";
import { makeStyles } from "@mui/styles";
import useTranslatedNavigate from "@src/services/useTranslateNavigate";
import { SESSION_NOTES_SECTIONS } from "@src/utils/constants";
import PropTypes from "prop-types";

const useStyle = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2.5, 3, 5, 3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(4),
      width: "110%",
    },
  },
}));

const SessionNotesPaper = ({ consult }) => {
  const classes = useStyle();
  const { t } = useTranslatedNavigate();
  const getNote = (question) =>
    consult.dossierNotes?.find((note) => note.question === question)?.answer;

  const noteContainsHtmlTags = (question) => {
    const note = getNote(question);
    if (!note) {
      return false;
    }
    const htmlRegex =
      /<(?!\/?(em|strong|sup|sub|ins|del|pre|code|kbd|samp|var|u|dl|dt|dd|q|details|summary|span|table|tr|td|th|thead|tbody|tfoot|caption)(?=>|\s.*>))([A-Za-z][A-Za-z0-9]*)(?:[^<>]*)>(?:.*?<\/\2>)?|<(?!\/?(em|strong|sup|sub|ins|del|pre|code|kbd|samp|var|u|dl|dt|dd|q|details|summary|span|table|tr|td|th|thead|tbody|tfoot|caption)(?=>|\s.*>))([A-Za-z][A-Za-z0-9]*)(?:[^<>]*)\/?>/;
    return htmlRegex.test(note);
  };
  return (
    <Paper className={classes.paper} elevation={3}>
      <p className="h6 mb-2">
        {t("MySessionsView.Notes.Notes.Title", {
          psychologistFullname: consult.employeeFullName,
        })}
      </p>
      <p className="sub1 mb-2">{t("MySessionsView.Notes.Notes.Background")}</p>
      {noteContainsHtmlTags(SESSION_NOTES_SECTIONS.REASON_BOOK) ? (
        <p
          className="body2 mb-3"
          dangerouslySetInnerHTML={{
            __html: getNote(SESSION_NOTES_SECTIONS.REASON_BOOK),
          }}
        />
      ) : (
        <MarkdownPreview
          source={getNote(SESSION_NOTES_SECTIONS.REASON_BOOK)}
          className="body2 mb-3"
        />
      )}
      <p className="sub1 mb-2">{t("MySessionsView.Notes.Notes.Discussed")}</p>
      {noteContainsHtmlTags(SESSION_NOTES_SECTIONS.SESSION_DISCUSSED) ? (
        <p
          className="body2 mb-3"
          dangerouslySetInnerHTML={{
            __html: getNote(SESSION_NOTES_SECTIONS.SESSION_DISCUSSED),
          }}
        />
      ) : (
        <MarkdownPreview
          source={getNote(SESSION_NOTES_SECTIONS.SESSION_DISCUSSED)}
          className="body2 mb-3"
        />
      )}
    </Paper>
  );
};
SessionNotesPaper.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  consult: PropTypes.any,
};

export default SessionNotesPaper;
